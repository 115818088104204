import * as Sentry from '@sentry/react';
function getShortApi() {
    var fullUrl = process.env.REACT_APP_API_HOST || '';
    var prefix = 'https://';
    var withoutPrefix = fullUrl.substring(prefix.length);
    return withoutPrefix.substring(withoutPrefix.indexOf('/'), -1);
}
if (process.env.REACT_APP_ENV_NAME === 'PROD') {
    Sentry.init({
        dsn: 'https://c17a9c04e5b749a3b8fbe0bb2445b63b@o4505125467258880.ingest.sentry.io/4505160822161408',
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [getShortApi()],
            }),
            new Sentry.Replay(),
        ],
        environment: process.env.REACT_APP_ENV_NAME,
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
